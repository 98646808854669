<!--Agent下载的短信接口页面-->
<template>
  <div class="center">
    <div class="url_text">
      <a href="https://yuankongit.cn/release/release/Agent.zip">点击下载</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "download_sms"
}
</script>

<style scoped>

</style>